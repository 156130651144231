'use client';

import { useEffect, useContext, useMemo } from 'react';
import { ProductContext } from '@/components/RootComponents/Product/context/productContext';
import { IProductDetails } from '@/components/SEO/ProductSchema/types';

const AVAILABILITIES = {
    inStock: 'InStock',
    outOfStock: 'OutOfStock',
};

const CONDITIONS = {
    newCondition: 'NewCondition',
};

const BRAND = 'Helly Hansen';

export const useProductSchema = (productDetails: IProductDetails, baseLinkUrl: string) => {
    const { isInStock, mediaGalleryEntries: images, productPrice, rating, reviews } = useContext(ProductContext);
    const baseUrl = new URL(baseLinkUrl).href;

    useEffect(() => {
        return () => {
            const yotpoProductSchemaCleanup = globalThis.document.querySelector(
                `script[class="y-rich-snippet-script"]`,
            );
            if (yotpoProductSchemaCleanup) {
                yotpoProductSchemaCleanup.remove();
            }
        };
    });

    const productSchema = useMemo<string>(() => {
        const hasRatingsAndReviews = rating?.average_score && reviews?.length;

        const aggregateRating = hasRatingsAndReviews
            ? {
                  aggregateRating: {
                      '@type': 'AggregateRating',
                      ratingCount: rating.total,
                      ratingValue: rating.average_score,
                  },
              }
            : {};

        const reviewsList = hasRatingsAndReviews
            ? {
                  review: reviews.map(({ content, score, user }) => ({
                      '@type': 'Review',
                      reviewRating: {
                          '@type': 'Rating',
                          ratingValue: score,
                      },
                      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
                      author: {
                          '@type': 'Person',
                          name: user.display_name,
                      },
                      reviewBody: content,
                  })),
              }
            : {};

        return JSON.stringify(
            {
                '@context': 'https://schema.org/',
                '@type': 'Product',
                brand: { '@type': 'Brand', name: BRAND },
                description: productDetails.description?.html,
                name: productDetails.name,
                offers: {
                    '@type': 'Offer',
                    availability: `https://schema.org/${isInStock ? AVAILABILITIES.inStock : AVAILABILITIES.outOfStock}`,
                    itemCondition: `https://schema.org/${CONDITIONS.newCondition}`,
                    price: productPrice?.minPrice,
                    priceCurrency: productPrice?.currency,
                    url: `${baseLinkUrl}${productDetails.url}`,
                },
                sku: productDetails.sku,
                ...aggregateRating,
                ...(images?.length && {
                    image: `${baseUrl}media/catalog/product${images[0].file}`,
                }),
                ...reviewsList,
            },
            null,
            4,
        );
    }, [rating, reviews, isInStock, productPrice, images, productDetails, baseLinkUrl, baseUrl]);

    return productSchema;
};
